import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Popup } from 'devextreme-react/popup'
import Button from 'devextreme-react/button'
import FileUploader from 'devextreme-react/file-uploader'
import RadioGroup, { RadioGroupTypes } from 'devextreme-react/radio-group'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { socketGet } from '../../socket'
import { ProgressBar } from 'devextreme-react/progress-bar'
import notify from '../../utils/notify'
import * as XLSX from 'xlsx'
import JSZip from 'jszip'
import mime from 'mime'

export default function UploadDialog(props: any) {
	const { onHide, onRefresh, onComplete, onErrorFields } = props
	const formElement = useRef<any | null>(null)
	const [selectedFile, setSelectedFile] = useState<File | Blob | null>(null)
	const [requiredFields, setRequiredFields] = useState<any[]>([])
	const [keyFields, setKeyFields] = useState<any[]>([])
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const process = searchParams.get('process')
	const socket = socketGet()
	const [loading, setLoading] = useState(false)
	const [percent, setPercent] = useState(-1)
	const [progressMessage, setProgressMessage] = useState('')
	const [progress, setProgress] = useState<any | null>(null)
	const [errorFields, setErrorFields] = useState<any[]>([])
	const [allowMultiUpload, setAllowMultiUpload] = useState(false)
	const [allowFolderUpload, setAllowFolderUpload] = useState(false)
	const [allowExtensions, setAllowExtensions] = useState<string[]>([])
	const [isFileTypeAllowed, setIsFileTypeAllowed] = useState(true)
	const [uploadType, setUploadType] = useState('File')
	const [countFiles, setCountFiles] = useState(-1)
	const [allFiles, setAllFiles] = useState(0)
	const [filename, setFilename] = useState('')

	const changeUploadType = useCallback(
		(e: RadioGroupTypes.ValueChangedEvent) => {
			setUploadType(e.value)
		},
		[setUploadType]
	)

	useEffect(() => {
		if (countFiles === 0) {
			setLoading(false)
			onComplete()
			setCountFiles(-1)
			setAllFiles(0)
			setPercent(-1)
			setProgressMessage('')
			setFilename('')
		}
	}, [onComplete, countFiles])

	useEffect(() => {
		if (progress && progress.files > 0) {
			setCountFiles(progress.files)
			setPercent(progress.percent)
			setProgressMessage(progress.message)
			setFilename(progress.filename)
			setAllFiles(progress.all_files)
			setLoading(true)
		}
	}, [progress])

	useEffect(() => {
		const handlerProgress = (data: any) => {
			const { percent, message, filename, files } = data
			if (message) {
				setProgressMessage(message)
			}
			if (filename) {
				setFilename(filename)
			}
			if (files) {
				setCountFiles(files)
			}
			if (percent === -1 || percent === undefined) {
				return
			}
			if (percent === 100) {
				setCountFiles(countFiles - 1)
				setPercent(0)
				onRefresh()
			} else {
				setPercent(percent)
			}
		}
		const handlerAbort = (data: any) => {
			if (data.message) {
				notify(data.message, 'error')
				setLoading(false)
				setCountFiles(-1)
				setAllFiles(0)
			} else {
				setTimeout(() => {
					setCountFiles(countFiles - 1)
				}, 1000)
			}
		}
		const handlerError = (data: any) => {
			if (data.fields) {
				setErrorFields(data.fields.toString().split(','))
				onErrorFields()
				setLoading(false)
				setCountFiles(-1)
				setAllFiles(0)
			}
		}

		if (socket) {
			socket.on('legacy_import_progress', handlerProgress)
			socket.on('legacy_import_abort', handlerAbort)
			socket.on('legacy_import_error', handlerError)

			return () => {
				socket.off('legacy_import_progress', handlerProgress)
				socket.off('legacy_import_abort', handlerAbort)
				socket.off('legacy_import_error', handlerError)
			}
		}
	}, [onRefresh, onErrorFields, socket, process, countFiles])

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/imports/fields?process=${process}`)
			.then(response => {
				setRequiredFields(response.data.fields)
				setKeyFields(response.data.keyFields)
				setAllowMultiUpload(response.data.allowMultiUpload)
				setAllowFolderUpload(response.data.allowFolderUpload)
				setAllowExtensions(response.data.allowExtensions || [])
				setProgress(response.data.progress)
			})
			.catch(error => {
				console.error(error)
			})
	}, [process])

	const handleFileSelection = (e: any) => {
		if (e.value.length > 1) {
			const zip = new JSZip()
			let cnt = 0
			e.value.forEach((file: File) => {
				if (file.type && file.name[0] !== '.' && file.name[0] !== '~') {
					zip.file(file.name, file)
					cnt++
				}
			})
			zip.generateAsync({
				type: 'blob',
				compression: 'DEFLATE',
				compressionOptions: {
					level: 9,
				},
			}).then(function (blob) {
				setSelectedFile(blob)
				setCountFiles(cnt)
				setAllFiles(cnt)
			})
		} else {
			const file = e.value[0]
			setSelectedFile(file || null)
			setCountFiles(file ? 1 : 0)

			if (file && allowExtensions.length > 0) {
				const fileExtension = file.name.split('.').pop()?.toLowerCase()
				setIsFileTypeAllowed(fileExtension ? allowExtensions.includes(`.${fileExtension}`) : false)
			} else {
				setIsFileTypeAllowed(true)
			}
		}
	}

	const ListItemField = (props: any) => {
		const { field } = props
		if (errorFields && errorFields.indexOf(field) > -1) {
			return <span style={{ color: 'red' }}>{field}</span>
		}
		return <span>{field}</span>
	}

	const handleUpload = () => {
		if (selectedFile) {
			const formData = new FormData()
			formData.append('process', process as string)
			formData.append('file', selectedFile)
			setLoading(true)
			setPercent(0)
			axios
				.post(`${API_FIBER_URL}/imports`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					if (!response.data.success) {
						notify('Incorrect filename', 'error')
						setLoading(false)
					}
				})
				.catch(error => {
					console.error('error', error)
					setLoading(false)
				})
		}
	}

	const handleDownloadClick = () => {
		const tsvRows = [requiredFields.join('\t')].join('\n')
		const workbook = XLSX.utils.book_new()
		const worksheet = XLSX.utils.aoa_to_sheet(tsvRows.split('\n').map(row => row.split('\t')))
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
		const wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' })
		const xlsxBlob = new Blob([wbout], { type: 'application/octet-stream' })
		const downloadLink = document.createElement('a')
		downloadLink.href = URL.createObjectURL(xlsxBlob)
		const fileName = `${process}.xlsx`
		downloadLink.setAttribute('download', fileName)
		document.body.appendChild(downloadLink)
		downloadLink.click()
		document.body.removeChild(downloadLink)
	}

	const statusFormat = useCallback(
		(ratio: number) => {
			if (!progressMessage) {
				return 'Wating...'
			}
			return progressMessage + ' [ ' + Math.round(ratio * 100) + '% ]'
		},
		[progressMessage]
	)

	const acceptTypesByExtention = (extensions: string[]) => {
		return extensions.map(ext => mime.getType(ext)).join(', ')
	}

	const uploadFolder = uploadType === 'Folder'

	const renderContent = () => {
		return (
			<>
				{allFiles > 1 && filename && <small>{filename}</small>}
				{loading && percent > -1 && (
					<ProgressBar min={0} max={100} value={percent} statusFormat={statusFormat} />
				)}
				<p style={{ fontSize: '20px', textAlign: 'center' }}>Import from excel</p>
				<form
					id="form"
					ref={formElement}
					method="post"
					action=""
					style={{ border: '1px solid lightgrey', borderRadius: '7px' }}
				>
					<div>
						<div style={{ width: '55%', float: 'left' }}>
							<FileUploader
								selectButtonText="CLICK TO SELECT"
								labelText=""
								uploadMode="useForm"
								onValueChanged={handleFileSelection}
								inputAttr={{ webkitdirectory: uploadFolder }}
								multiple={uploadFolder || allowMultiUpload}
								disabled={loading}
								{...(allowExtensions.length > 0 && {
									allowedFileExtensions: allowExtensions,
									accept: acceptTypesByExtention(allowExtensions),
								})}
							/>
						</div>
						{allowFolderUpload && (
							<div style={{ float: 'left', marginTop: '15px' }}>
								<RadioGroup
									items={['File', 'Folder']}
									value={uploadType}
									layout="horizontal"
									onValueChanged={changeUploadType}
									disabled={loading}
								/>
							</div>
						)}
					</div>
					<Button
						text="Upload"
						onClick={handleUpload}
						disabled={loading || !selectedFile || !isFileTypeAllowed}
						width={'30dvw'}
					/>
				</form>
				{requiredFields.length > 0 && (
					<>
						<br />
						<Button text="Download Excel Templete" onClick={handleDownloadClick} icon="download" />
						<br />
						<p>
							<strong>Required Fields:</strong>
						</p>
						<ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
							{requiredFields.map((item, index) => (
								<li key={index}>
									{(Array.isArray(keyFields) && keyFields.includes(item)) || keyFields === item ? (
										<>
											<ListItemField field={item} /> (*)
										</>
									) : (
										<ListItemField field={item} />
									)}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		)
	}

	return (
		<Popup
			visible={true}
			showTitle={false}
			contentRender={renderContent}
			position="center"
			width={'30dvw'}
			minWidth={'400px'}
			height={'80dvh'}
			hideOnOutsideClick={() => {
				return true
			}}
			onHiding={onHide}
		/>
	)
}

import React, { useCallback, useEffect, useState, useRef } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import Button from 'devextreme-react/button'
import DataGrid, {
	Column,
	ColumnChooser,
	Scrolling,
	Paging,
	FilterRow,
	Toolbar,
	Item,
	HeaderFilter,
	Editing,
	Export,
	Lookup,
	Selection,
	KeyboardNavigation,
} from 'devextreme-react/data-grid'
import {
	loadData,
	insertData,
	editData,
	deleteData,
	fixDataType,
	handleOptionChange,
	handleExporting,
	renderUrl,
	filterStore,
	lookupStore,
} from '../../utils/model-data'
import { useSharePoint, confirmDialog } from '../../components'
import notify from '../../utils/notify'
import axios from 'axios'

export default function MasterDetail(props: any) {
	const { folder, model, master, filter, settings, fields, sizes } = props

	const {
		key,
		urls,
		lookups,
		locks,
		locksEdit,
		inserting,
		updating,
		deleting,
		duplicating,
		rowColors,
		cellColors,
		buttons,
		hovers,
	} = settings

	const [dataSource, setDataSource] = useState()
	const [isNewRow, SetIsNewRow] = useState(false)
	const [editorMode, setEditorMode] = useState<boolean>(false)
	const [selectedRow, setSelectedRow] = useState<any>(null)
	const [changes, setChanges] = useState<any[]>([])

	const gridRef = useRef<DataGrid | null>(null)
	const { sharePointLogged } = useSharePoint()

	interface RowColor {
		field: string
		fieldValue: any
		field2?: string
		fieldValue2?: any
		operator: 'AND' | 'OR'
		color: string
	}

	useEffect(() => {
		if (key) {
			const ds = new CustomStore({
				key: key,
				load: loadOptions => {
					return loadData(`view-detail`, loadOptions, { folder, model, master })
				},
				insert: values => {
					return insertData('view-detail', { values, folder, model, master, filter })
				},
				update: (key, values) => {
					return editData('view-detail', { key, values, folder, model, master })
				},
				remove: key => {
					return deleteData('view', { key, folder, model, master })
				},
			})
			setDataSource(ds as any)
		}
	}, [folder, model, master, key, filter])

	const allowEditing = useCallback(
		(field: string, allowEditing: any): boolean => {
			if (allowEditing) {
				if (isNewRow) {
					return allowEditing
				}
				if (locksEdit.length === 0) {
					return allowEditing
				}
			}
			if (field === key) {
				return false
			}
			if (locks === true) {
				if (isNewRow) {
					return false
				}
				if (locksEdit.length === 0) {
					return false
				}
			}
			const locksAr = !isNewRow && locksEdit.length > 0 ? locksEdit : (locks as [])
			const ndx = locksAr.indexOf(field as never)
			if (ndx > -1) {
				return false
			}
			return true
		},
		[isNewRow, locks, locksEdit, key]
	)

	const rowPrepared = (e: any) => {
		if (e.rowType === 'data') {
			const { data: rowData, rowElement } = e
			rowColors.forEach(({ field, fieldValue, field2, fieldValue2, operator, color }: RowColor) => {
				let shouldApplyColor = false
				switch (operator) {
					case 'AND':
						shouldApplyColor = rowData[field] === fieldValue && rowData[field2!] === fieldValue2
						break
					case 'OR':
						shouldApplyColor = rowData[field] === fieldValue || rowData[field2!] === fieldValue2
						break
					default:
						shouldApplyColor = rowData[field] === fieldValue
				}
				if (shouldApplyColor) {
					rowElement.style.backgroundColor = color
				}
			})
		}
	}

	const cellPrepared = (e: any) => {
		if (e.rowType === 'data') {
			const rowData = e.data
			cellColors.forEach((item: any) => {
				if (
					e.column.dataField === item.field &&
					rowData[item.field] &&
					rowData[item.field] === item.fieldValue
				) {
					e.cellElement.style.cssText = `background-color: ${item.color}`
				}
			})
		} else if (e.rowType === 'header') {
			const tooltipText = hovers[e.column.dataField] || ''
			e.cellElement.setAttribute('title', tooltipText)
		}
	}

	const handleClick = (data: any, model: string, filter: any, composeFilter: any) => {
		let filterClone: any = []
		if (composeFilter) {
			filterClone = JSON.parse(JSON.stringify(composeFilter))
			Object.entries(filterClone).forEach(([index, filterItem]) => {
				if (Array.isArray(filterItem)) {
					filterClone[index as any][2] = data[0][filterItem[2]]
				}
			})
		} else {
			filterClone = filter.slice()
			const value = data[0][filterClone[2]]
			filterClone[2] = value
		}
		const base64 = btoa(JSON.stringify(filterClone))
		const url = `/m?folder=${folder}&model=${model}&filter=${encodeURIComponent(base64)}`
		window.open(url, '_blank')
	}

	function renderFilterColumn(dataField: string) {
		if (dataField.indexOf('.') > -1) {
			return (
				<Column
					key={dataField}
					dataField={dataField}
					dataType="string"
					visible={false}
					showInColumnChooser={false}
				/>
			)
		}
		return null
	}

	const handleSaveData = async () => {
		const clickedAction = await confirmDialog('Multiple edit', `You are going to edit ${changes.length} rows.`)
		if (clickedAction) {
			let url = `${API_FIBER_URL}/view-detail/massUpdate`
			const requestData = {
				model: model,
				folder: folder,
				master: master,
				filter: filter,
				changes: changes,
			}
			axios
				.patch(url, requestData)
				.then(response => {
					notify(`Data updated successfully`, 'success')
					gridRef.current?.instance?.refresh()
					setEditorMode(false)
					setChanges([])
				})
				.catch(error => {
					notify(`Multiple edit error!`, 'error')
				})
		}
	}

	const onFocusedRowChanged = useCallback(
		(e: any) => {
			if (key && e.row && e.row.data) {
				setSelectedRow(e.row.data)
			}
		},
		[key]
	)

	const handleDuplicate = () => {
		if (gridRef.current && gridRef.current.instance && selectedRow) {
			const newData = { ...selectedRow }
			// Remove the key field to create a new entry
			if (Array.isArray(key)) {
				key.forEach(k => delete newData[k])
			} else {
				delete newData[key]
			}

			// Use addRow instead of insert
			gridRef.current.instance.addRow()
			Object.keys(newData).forEach(field => {
				gridRef.current?.instance.cellValue(0, field, newData[field as keyof typeof newData])
			})
		}
	}

	return (
		<React.Fragment>
			{dataSource && (
				<DataGrid
					ref={gridRef}
					height={400}
					className={'dx-card wide-card'}
					dataSource={dataSource}
					dateSerializationFormat="yyyy-MM-dd"
					allowColumnReordering={true}
					remoteOperations={true}
					focusedRowEnabled={true}
					autoNavigateToFocusedRow={false}
					defaultFocusedRowIndex={0}
					columnAutoWidth={true}
					showBorders={false}
					allowColumnResizing={true}
					columnResizingMode="widget"
					defaultFilterValue={filter}
					onExporting={e => handleExporting('view-detail', e, { folder, model, master })}
					onOptionChanged={(e: any) => {
						if (e.fullName === 'editing.changes') {
							setChanges(e.value)
						}
						handleOptionChange('view-detail', e, { folder, model, master }, (action: string, data: any) => {
							props.refreshSettings(action, data)
						})
					}}
					onRowPrepared={rowPrepared}
					onCellPrepared={cellPrepared}
					onInitNewRow={() => SetIsNewRow(true)}
					onEditCanceled={() => SetIsNewRow(false)}
					onRowInserted={() => SetIsNewRow(false)}
					onFocusedRowChanged={onFocusedRowChanged}
				>
					<Scrolling mode="virtual" useNative={true} />
					{editorMode && (
						<KeyboardNavigation
							enabled={true}
							editOnKeyPress={true}
							enterKeyAction={'startEdit'}
							enterKeyDirection={'column'}
						/>
					)}
					<Editing
						startEditAction={editorMode ? 'click' : 'dblClick'}
						mode={editorMode ? 'batch' : 'cell'}
						allowAdding={inserting}
						allowUpdating={updating}
						confirmDelete={true}
						allowDeleting={editorMode ? false : deleting}
					/>
					<Paging defaultPageSize={50} />
					<ColumnChooser enabled />
					<HeaderFilter visible={true} />
					<FilterRow visible={true} applyFilter={'onClick'} />
					<Selection mode="single" />
					{fields.map((field: any, ndx: number) => {
						const dataType = fixDataType(field.dataType, field.dataField)
						return (
							<Column
								key={field.dataField}
								dataField={field.dataField}
								dataType={dataType}
								{...(dataType === 'boolean'
									? {
											calculateCellValue: (rowData: any) => {
												if (rowData[field.dataField] === true) {
													return true
												}
												return parseInt(rowData[field.dataField]) ? true : false
											},
									  }
									: {})}
								format={dataType === 'date' ? 'dd/MM/yyyy' : ''}
								caption={field.dataField}
								visible={field.visible}
								showEditorAlways={editorMode || dataType === 'boolean'}
								allowEditing={allowEditing(field.dataField, field.allowEditing)}
								allowFiltering={lookups[field.dataField] ? false : true}
								allowHeaderFiltering={lookups[field.dataField] ? true : false}
								width={sizes[ndx] ?? undefined}
								minWidth={50}
								cellRender={
									urls.indexOf(field.dataField as never) > -1
										? ({ data }) => {
												return renderUrl(data[field.dataField], sharePointLogged)
										  }
										: undefined
								}
							>
								{lookups[field.dataField] && (
									<HeaderFilter
										allowSelectAll={false}
										dataSource={filterStore(field.dataField, lookups[field.dataField])}
									/>
								)}
								{lookups[field.dataField] && (
									<Lookup
										dataSource={lookupStore(lookups[field.dataField])}
										valueExpr="value"
										displayExpr="text"
									/>
								)}
							</Column>
						)
					})}
					{!Array.isArray(filter[0]) && renderFilterColumn(filter[0])}
					{Array.isArray(filter[0]) &&
						filter.map((filterItem: string[]) => renderFilterColumn(filterItem[0]))}

					<Toolbar>
						{Object.entries(buttons).map(([key, button]: any) => (
							<Item key={key} location="before">
								<Button
									icon={button.icon}
									hint={button.text}
									onClick={() => {
										if (gridRef.current && gridRef.current.instance) {
											handleClick(
												gridRef.current.instance.getSelectedRowsData(),
												button.model as string,
												button.filter,
												button.composeFilter
											)
										}
									}}
								/>
							</Item>
						))}
						<Item name="applyFilterButton" location="before" />
						<Item location="before">
							<Button
								icon="revert"
								hint="Clear Filter"
								onClick={() => {
									if (gridRef.current && gridRef.current.instance) {
										gridRef.current.instance.clearFilter('row')
									}
								}}
							/>
						</Item>
						<Item location="before">
							<Button
								icon="refresh"
								hint="Refresh"
								disabled={editorMode ? true : false}
								onClick={() => {
									if (gridRef.current && gridRef.current.instance) {
										gridRef.current.instance.refresh()
									}
								}}
							/>
						</Item>
						<Item location="before">
							<Button
								icon="edit"
								hint="Edit Mode"
								disabled={!updating}
								onClick={async () => {
									if (editorMode) {
										const clickedAction = await confirmDialog(
											'Confirm Exit from Edit Mode',
											`Exiting edit mode will discard changes made to ${changes.length} rows. Are you sure you want to proceed?`
										)
										if (clickedAction) {
											setEditorMode(false)
											setChanges([])
										}
									} else {
										setEditorMode(true)
									}
								}}
							/>
						</Item>
						<Item location="before">
							<Button
								icon="save"
								hint="Save"
								className={editorMode ? 'flashButton' : undefined}
								disabled={!updating || !editorMode}
								onClick={handleSaveData}
							/>
						</Item>
						<Item name="addRowButton" location="before" />
						{duplicating && (
							<Item location="before">
								<Button
									icon="copy"
									hint="Duplicate"
									disabled={!editorMode || Array.isArray(selectedRow) ? true : false}
									onClick={handleDuplicate}
								/>
							</Item>
						)}
						<Item name="exportButton" location="before" />
						<Item name="columnChooserButton" location="before" />
					</Toolbar>
					<Export enabled={true} />
				</DataGrid>
			)}
		</React.Fragment>
	)
}
